<template>
	<PageLayout :title="$t('dashboard.deposit')" :subtitle="$t('deposit.select.title')" wrapper-class="select-wrapper">
		<div v-if="getAvailableMethodsFetchStatus.success && !checkingEdgeCases">
			<div class="available-method-wrapper">
				<div v-for="(method, index) in availableMethods" :key="index" class="methods">
					<div class="available-methods">
						<div class="method-heading black-method logo-method" @click="showMethodDetails(method.name)">
							<img v-if="!!method.icon" :src="method.icon" style="max-height: 70%; object-fit: contain" />
							<span v-if="!method.icon">{{ method.name }}</span>
						</div>
						<template v-if="innerWidth > 576 || methodName.includes(method.name)">
							<div class="method-heading gray-method info-value min-value">
								<div class="attr">
									{{ $t('global.min') }}
								</div>
								<div class="value">
									{{ formatCurrency(method.min || 0, method.depositCurrency) }}
								</div>
							</div>
							<div class="method-heading black-method info-value max-value">
								<div class="attr">
									{{ $t('global.max') }}
								</div>
								<div class="value">
									{{ method.max ? formatCurrency(method.max, method.depositCurrency) : '∞' }}
								</div>
							</div>
							<div class="method-heading gray-method info-value fee-value">
								<div class="attr">{{ $t('global.fee') }}*</div>
								<div class="value">
									{{ $t('global.free') }}
								</div>
							</div>
							<div class="method-heading black-method go-to-method">
								<button type="button" class="button primary-button" @click="goToMethod(method.id)">
									{{ $t('deposit.top-up') }}
								</button>
							</div>
						</template>
					</div>
					<template v-if="innerWidth > 576 || methodName.includes(method.name)">
						<div class="description-wrapper">
							<div class="description-text">
								{{ $t(`deposit.method-descriptions.${method.id}`) }}
							</div>
						</div>
					</template>
				</div>
			</div>
			<div class="methods-countries-wrapper">
				<div>
					<img class="flag" :src="getFlagImage" />
				</div>
				<div>
					{{ $t('deposit.payments-based-on-country') }}
					<a href="/profile">{{ $t('actions.click-country-change') }}</a>
				</div>
			</div>
			<p class="fineprint">
				{{ $t('deposit.additional-info') }}
			</p>
		</div>
		<div v-if="getAvailableMethodsFetchStatus.loading || checkingEdgeCases" class="loader-wrapper">
			<loader />
		</div>
		<FormError :error="getAvailableMethodsFetchStatus.error" />
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import FormError from '@/shared/forms/FormError';

const loader = () => import('../../../shared/loader/Loader.vue');

export default {
	name: 'DepositSelect',
	components: {
		FormError,
		PageLayout,
		loader,
	},
	data() {
		return {
			innerWidth: null,
			methodName: [],
			checkingEdgeCases: true,
		};
	},
	computed: {
		...mapGetters(['availableMethods', 'getUser', 'getAvailableMethodsFetchStatus']),
		countryCode() {
			return this.getUser.country;
		},
		getFlagImage() {
			try {
				return require(`../../../assets/circular-flags/${this.countryCode.toLowerCase()}.svg`);
			} catch (e) {
				return require('../../../assets/circular-flags/nocountry.svg');
			}
		},
	},
	watch: {
		availableMethods: {
			handler(newValue) {
				if (newValue) {
					this.redirectByAvailableMethodsLength(newValue);
				}
			},
			immediate: true,
		},
	},
	beforeMount() {
		this.innerWidth = window.innerWidth;
		window.addEventListener('resize', this.onResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		async redirectByAvailableMethodsLength(availableMethods) {
			if (availableMethods.length === 1) {
				const { id } = availableMethods[0];
				this.goToMethod(id, true);
			} else if (availableMethods.length === 0) {
				this.goToMethod('no_deposit', true);
			}

			this.checkingEdgeCases = false;
		},
		goToMethod(id, replace = false) {
			// removes hideBack from query params - Vue router forwards all query params when in iFrame it seems
			// eslint-disable-next-line no-unused-vars
			const { hideBack, ...rest } = this.$route.query;
			const { walletId } = this.$route.params;
			const url = `/payments/deposit/${walletId}/${id}`;

			if (replace) {
				this.$router.replace({
					path: url,
					query: rest,
				});
			} else {
				this.$router.push({
					path: url,
					query: rest,
				});
			}
		},
		formatCurrency(amount, depositCurrency) {
			const formatter = new Intl.NumberFormat('en', {
				style: 'currency',
				currency: depositCurrency,
				currencyDisplay: 'symbol',
			});
			return formatter.format(amount);
		},
		showMethodDetails(methodName) {
			if (this.innerWidth <= 576) {
				const index = this.methodName.indexOf(methodName);
				if (index > -1) {
					this.methodName.splice(index, 1);
				} else {
					this.methodName.push(methodName);
				}
			} else {
				this.methodName = [];
			}
		},
		onResize() {
			this.innerWidth = window.innerWidth;
			if (this.innerWidth <= 576) {
				this.methodName = [];
			}
		},
	},
};
</script>
<style scoped>
.methods-countries-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 12px;
}

.flag {
	margin-right: 10px;
}

::v-deep .select-wrapper {
	max-width: 1200px !important;
}
</style>
